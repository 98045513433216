import Img from 'gatsby-image'
import Container from '../components/Container'
import Form from '../components/Form'

import { graphql, navigate, PageProps, useStaticQuery } from 'gatsby'
import { Activity, Film, Globe, HelpCircle } from 'react-feather'

import { Site } from '../components/Site'
import { SEO } from '../SEO'

import React, { useContext, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'

const StartADemo: React.FC<PageProps> = props => {
  const themeContext = useContext(ThemeContext)
  const [submitted, setSubmitted] = useState(false)
  const [userName, setUserName] = useState('')

  const data = useStaticQuery(graphql`
    query Contact {
      file(relativePath: { eq: "mockup.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Site seo={SEO.contact} {...props}>
      <Container>
        <StyledSignUp>
          <SignUpWrapper>
            <LeftPanel>
              <Img fluid={data.file.childImageSharp.fluid} alt='daisee user on laptop' />
              <Summary>
                <SummaryHeading>We&#39;re always ready to help</SummaryHeading>
                <Support>
                  <Activity size={18} />
                  <span>Technical Support</span>
                </Support>
                <Support>
                  <HelpCircle size={18} />
                  <span>Enquiries</span>
                </Support>
                <Support>
                  <Globe size={18} />
                  <span>Partnerships</span>
                </Support>
                <Support>
                  <Film size={18} />
                  <span>Media Opportunities</span>
                </Support>
              </Summary>
            </LeftPanel>

            <RightPanel>
              <FormHeading>Contact daisee</FormHeading>
              <Form
                setSubmitted={setSubmitted}
                setUserName={setUserName}
                formName='contact'
                successMessage='Thank you, we will be in touch with you soon'
                errorMessage='Oops, something went wrong - please try again'
                callback={() => navigate('/thankyou')}
              />
            </RightPanel>
          </SignUpWrapper>
        </StyledSignUp>
      </Container>
    </Site>
  )
}

const Support = styled.div`
  display: grid;
  gap: 0.5rem;
  color: #fff;
  grid-template-columns: 0.1fr 1fr;
  padding-bottom: 1.5rem;
  align-items: center;
`

const ThanksWrapper = styled.div`
  padding: 0 1.5rem;
  font-weight: 500;
`

const Summary = styled.article`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`

const SummaryHeading = styled.h2`
  margin: 0 0 1.5rem;
  font-size: 1.25rem;
  color: #fff;
`

const StyledSignUp = styled.section`
  position: relative;
  z-index: 1;
`

const SignUpWrapper = styled.div`
  display: grid;
  grid-template-columns: 38.2% 61.8%;
  width: 100%;
  height: 100vh;
  padding-top: 9rem;
  overflow: hidden;

  @media all and (max-width: 56.25em) {
    grid-template-columns: 1fr;
    height: auto;
  }
`

const LeftPanel = styled.div`
  background: ${({ theme }) => theme.colors.purple};
  background-size: 400% 400%;
  width: 100%;
  height: 100%;
`

const FormHeading = styled.h1`
  margin: 0.75rem 0 3rem;
  letter-spacing: -0.5px;
  display: flex;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -2rem;
    width: 4rem;
    height: 4px;
    background: ${({ theme }) => theme.colors.green};
  }

  svg {
    margin-left: 1rem;
  }
  @media all and (max-width: 36.625em) {
    font-size: 1.75rem;
    line-height: 1.5;
  }
`

const RightPanel = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  background: #fff;
  position: relative;
  height: fit-content;

  @media all and (max-width: 56.25em) {
    grid-row: 1;
    padding-bottom: 3rem;
  }
`

export default StartADemo
